import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

const Swal_tea_room = Swal.mixin({
  width: 700,
  showCloseButton: true,
  showConfirmButton: false,
  customClass: {
    popup: 'tea-room-popup',
  },
});

const Swal_hint = Swal.mixin({
  width: 300,
  showCloseButton: true,
  showConfirmButton: false,
  backdrop: 'transparent',
  customClass: {
    popup: 'popup-hint',
    htmlContainer: 'html-hint',
  },
});

window.Swal = Swal;
window.Swal_tea_room = Swal_tea_room;
window.Swal_hint = Swal_hint;
