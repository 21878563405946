import AOS from 'aos';
import 'public/js/svgxuse.min';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, HashNavigation , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, HashNavigation, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  // Mac
  if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    $('body').addClass('is-Mac');
  }

  //AOS
  AOS.init({
    disable: 'mobile',
  });
  window.addEventListener('load', AOS.refresh)

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  if ($(window).scrollTop() > 100) {
    $('.header').addClass("min-header");
    $('.back-to-top').addClass("show");
  } else {
    $('.header').removeClass("min-header");
    $('.back-to-top').removeClass("show");
  }

  // transparent header
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 100) {
      $('.back-to-top').addClass("show");
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
      $('.back-to-top').removeClass("show");
    }
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $(".header-menu-link").hover(function () {
    if ($(window).width() > 860) {
      var isHovered = $(this).is(":hover");
      $(this).find('.header-menu-dropdown-inner').slideToggle(300);
    }
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }
  // custom-editor iframe responsive
  if ($('.custom-editor iframe').length >= 1) {
    $('.custom-editor iframe').wrap('<div class="iframe-outer"></div>');
  }

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  //mouse
  if ($('#cursor').length >= 1) {
    const cursor = document.getElementById('cursor');
    document.addEventListener('mousemove', e => {
      cursor.setAttribute("style", "top: " + (e.clientY - 25) + "px; left: " + (e.pageX + 10) + "px;")
    })
  }

  //index vision
  if ($('.index-tea-house')) {
    $('.tea-house').hover(function() {
      let name = $(this).data('house');
      $('.tea-house-item').removeClass('is-active');
      $('#'+name).addClass('is-active');
    });
    $('.tea-house-item').mouseout(function() {
      $('.tea-house-item').removeClass('is-active');
    });
  }

  // index announcement
  var tickerHeight = $('.index-announcement').outerHeight();
  function moveTop() {
    $('.index-announcements').animate({
      top: -tickerHeight
    }, 600, function () {
      $('.index-announcement:first-child').appendTo('.index-announcements');
      $('.index-announcements').css('top', '');
    });
  }
  if ($(window).width() < 900) {
    $('.index-announcement:last-child').prependTo('.index-announcements');
    setInterval(function () {
      moveTop();
    }, 4000);
  }

  $('#index_announcement_switch').on('click', function() {
    $(this).toggleClass('is-active');
    $('#index_announcement').toggleClass('is-active');
  });

  var indexEvents = new Swiper('#indexEvents', {
    slidesPerView: 1.2,
    slidesOffsetBefore: 30,
    slidesOffsetAfter: 50,
    peed: 1000,
    breakpoints: {
      640: {
        slidesOffsetBefore: 25,
        slidesPerView: 2.2,
      },
      1200: {
        slidesOffsetBefore: 0,
        slidesPerView: 2.8,
      },
      2000: {
        slidesOffsetBefore: 0,
        slidesPerView: 3.5,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  var teaRoom = new Swiper('#teaRoom', {
    slidesPerView: 1.5,
    peed: 1000,
    centeredSlides: true,
    initialSlide: 2,
    hashNavigation: true,
    breakpoints: {
      640: {
        slidesOffsetBefore: 25,
        slidesPerView: 2,
      },
      1200: {
        slidesOffsetBefore: 0,
        slidesPerView: 4,
      },
      2200: {
        slidesOffsetBefore: 0,
        slidesPerView: 5.5,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  var setMeal = new Swiper('#setMeal', {
    slidesPerView: 1,
    spaceBetween: 15,
    peed: 1000,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });
});
