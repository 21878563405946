$(function () {
  const container = document.querySelector('#guide_map');

  if(container) {
    let startY;
    let startX;
    let scrollLeft;
    let scrollTop;
    let isDown;

    container.addEventListener('mousedown', e => mouseIsDown(e));
    container.addEventListener('mouseup', e => mouseUp(e))
    container.addEventListener('mouseleave', e => mouseLeave(e));
    container.addEventListener('mousemove', e => mouseMove(e));

    function mouseIsDown(e) {
      isDown = true;
      startY = e.pageY - container.offsetTop;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
    }
    function mouseUp(e) {
      isDown = false;
    }
    function mouseLeave(e) {
      isDown = false;
    }
    function mouseMove(e) {
      if (isDown) {
        e.preventDefault();
        //Move vertcally
        const y = e.pageY - container.offsetTop;
        const walkY = y - startY;
        container.scrollTop = scrollTop - walkY;

        //Move Horizontally
        const x = e.pageX - container.offsetLeft;
        const walkX = x - startX;
        container.scrollLeft = scrollLeft - walkX;

      }
    }

    $('.guide-info-tab').on('click', function() {
      const id = $(this).attr('data-info-tab');
      pane_open(id);
      $('.map-icon').removeClass('focus');
      $('.guide-info-detail').removeClass('is-active');
      $('.map-item').attr('data-active', 'false');
    });

    $('.guide-info-li').on('click', function() {
      const id = $(this).attr('data-info-name');
      $('#' + id).attr('data-active', 'true');
      if ($('[data-detail=' + id+ ']').length >= 1) {
        detail_open(id);
      }
    });

    $('.detail-header').on('click', function() {
      const id = $(this).parent('.guide-info-detail').attr('data-detail')
      const back_id = $(this).parent('.guide-info-detail').attr('data-back')
      $('#' + id).attr('data-active', 'false');
      pane_open(back_id)
      detail_close();
    });

    $('.map-item').on('click', function() {
      const active = $(this).attr('data-active');
      const block = $(this).attr('data-block');
      const id = $(this).attr('id');
      pane_open(block);
      $('.map-icon').removeClass('focus');
      if (active == 'true') {
        $(this).attr('data-active', 'false');
        detail_close();
      }else{
        $('.map-item').attr('data-active', 'false');
        $(this).attr('data-active', 'true');
        if ($('.guide-info-detail[data-detail=' + id + ']').length >= 1) {
          detail_open(id);
        }
      }
    });
    $('.guide-icon-li').on('click', function() {
      const icon = $(this).find('.guide-icon').attr('data-icon');
      $(this).addClass('focus').siblings().removeClass('focus');
      $('.map-icon').removeClass('focus');
      $('.map-icon[data-icon='+ icon+']').addClass('focus');
    });
    function pane_open(id) {
      $('.guide-info-pane[data-info-pane=' + id + ']').addClass('is-active');
      $('.guide-info-pane[data-info-pane=' + id + ']').siblings().removeClass('is-active');
    }
    function detail_open(id) {
      $('.guide-info-detail-content').addClass('is-active');
      $('.guide-info-detail[data-detail=' + id + ']').siblings().removeClass('is-active');
      $('.guide-info-detail[data-detail=' + id + ']').addClass('is-active');
    }
    function detail_close() {
      $('.guide-info-detail').removeClass('is-active');
      $('.guide-info-detail-content').removeClass('is-active');
    }
  }
});
